import _isEqual from 'lodash/isEqual';

const parseQueryParamToArray = (param = '') => {
  if (!param) {
    return [];
  }
  return param
    .split(',')
    .map((v) => {
      const value = v.trim();
      const valueAsNumber = +value;
      return isNaN(valueAsNumber) ? value : valueAsNumber;
    })
    .filter((v) => v);
};

export function parseRouteQuery(params: { [x: string]: string }, validTypes = {}) {
  const validParamTypes: { [x: string]: string[] } = {
    array: [],
    boolean: [],
    number: [],
    string: [],
    ...validTypes,
  };
  const result: { [x: string]: any } = {};

  for (const pName in params) {
    const res = pName.match(/(?<name>\w+)$/i);
    if (!res) {
      continue;
    }

    const { groups: { name } = { name: '' } } = res;
    let value: any = params[pName];
    if (!value) {
      value = null;
    } else if (validParamTypes.array.includes(name)) {
      value = parseQueryParamToArray(value);
    } else if (validParamTypes.boolean.includes(name)) {
      value = value === '1';
    } else if (validParamTypes.number.includes(name)) {
      value = Number(value);
      value = !isNaN(value) ? value : null;
    }
    result[name] = value;
  }

  return result;
}

export function makeRouteQueryFromData(data: { [x: string]: any } = {}) {
  const params: { [x: string]: any } = {};

  for (const prop in data) {
    let value = data[prop];
    const paramName = prop;

    if (typeof data[prop] === 'boolean') {
      value = data[prop] === true ? '1' : '0';
    } else if (Array.isArray(data[prop])) {
      value = data[prop].join(',');
      value = value.length ? value : null;
    }

    if (value === 0 || (value !== null && value !== undefined && value !== '')) {
      params[paramName] = value;
    }
  }

  return params;
}

export function areRouteQueriesDifferent(
  currentRouteQuery: { [key: string]: string | (string | null)[] },
  newRouteQuery: { [key: string]: string | (string | null)[] }
) {
  return !_isEqual(currentRouteQuery, newRouteQuery);
}

export function routeToDestinationMap(id: string, slug: string) {
  return { name: 'destination-map', params: { id, slug } };
}
